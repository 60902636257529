<template>
  <div class="se-card-box content">
    <search @submit="submitSearch" />

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      size="middle"
      @change="sortChange"
    >
      <span slot="full_name" slot-scope="text, record">
        <router-link :to="{ name: 'terminal_details', params: { id: record.id } }">
          <span>{{ text | ellipsis }}</span>
        </router-link>
      </span>
    </a-table>

    <pagination
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      :total-count="pagination.total_count"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Search from '@/views/terminals/Search'
import Pagination from '@/components/Pagination/index'
import { findTerminals } from '@/api/terminal'

export default {
  name: 'Terminals',
  components: {
    Search,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0, 10) + '...'
      }
      return value
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '终端设备名称',
          dataIndex: 'full_name',
          scopedSlots: { customRender: 'full_name' },
          ellipsis: true
        },
        {
          title: '身份标识',
          dataIndex: 'identity_no'
        },
        {
          title: '所属集抄器',
          dataIndex: 'gateway_full_name',
          ellipsis: true
        },
        {
          title: '客户名称',
          dataIndex: 'customer_enterprise_name',
          ellipsis: true
        },
        {
          title: '客户账号',
          dataIndex: 'customer_username',
          ellipsis: true
        },
        {
          title: '客户联系人',
          dataIndex: 'customer_full_name',
          ellipsis: true
        },
        {
          title: '入库时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        }
      ]
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findTerminals(Object.assign({}, this.query, this.sort)).then(res => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  box-shadow: 0 0 10px #ccc;
  margin: 0 5px 5px 5px;
}
</style>
